'use client';
import Image from 'next/image';
import PostReadAndReadTime from './PostDateAndReadTime';
import { IKLink } from '../shared/material';
import { Article as ArticleType } from '@/types/types';
import PostCategoryTags from './PostCategoryTags';

export default function SinglePostCard({
  post,
  headingClass,
  imageClass,
  className,
  headingParentClass,
}: {
  post?: ArticleType;
  className?: string;
  headingClass?: string;
  imageClass?: string;
  headingParentClass?: string;
}) {
  return (
    <div
      className={`group flex w-full flex-row gap-5 transition-all duration-300 md:gap-6 ${className || ''}`}
    >
      {post?.image?.url && (
        <IKLink
          link={{
            url: `${post?.slug}`,
            type: 'internal',
            document: 'articles',
          }}
          className={`inline min-w-fit ${imageClass || ''}`}
        >
          <Image
            src={post?.image.url}
            alt={post?.image.alt || post?.title}
            width={154}
            height={154}
            className={`h-[120px] w-[120px] rounded-lg object-cover transition-all duration-300 group-hover:scale-[1.01] md:h-[154px] md:w-[154px]`}
          />
        </IKLink>
      )}
      <div className='content relative grid gap-3 md:gap-3.5'>
        <PostCategoryTags tags={post?.tags} />

        <div className='group flex flex-col gap-2 md:gap-2'>
          <IKLink
            link={{
              url: `${post?.slug}`,
              type: 'internal',
              document: 'articles',
            }}
          >
            <h2
              className={`relative !line-clamp-3 inline-block font-sans text-lg font-medium leading-6 text-charcoal transition-all duration-500 sm:line-clamp-2 md:text-[20px] ${headingParentClass || ''}`}
            >
              <span title={post?.title} className={` ${headingClass || ''}`}>
                {post?.title}
              </span>
              {/* Underline will appear dynamically under the text */}
            </h2>
          </IKLink>
          {post?.excerpt && (
            <p className='hidden overflow-hidden text-ellipsis font-sans text-base text-[#282825] sm:line-clamp-2'>
              {post?.excerpt}
            </p>
          )}
          <PostReadAndReadTime
            readTime={post?.readTime}
            displayDate={
              post?.publishedAt ?? post?.updatedAt ?? post?.createdAt
            }
          />
        </div>
      </div>
    </div>
  );
}
