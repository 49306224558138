export default function PostCategoryTag({
  readTime,
  displayDate,
}: {
  readTime: number | null | undefined;
  displayDate: Date | string | undefined;
}) {
  function formatDate(date: string) {
    const options: any = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = new Date(date)
      .toLocaleDateString('en-GB', options)
      .toUpperCase();
    return formattedDate
      .replace(' ', ' ')
      .replace(/(\w{3}) (\d{4})$/, '$1, $2');
  }
  const formattedDate = formatDate(displayDate as string);
  return (
    <div className='tracking flex items-center space-x-2 text-[10px] tracking-[1.7px]'>
      {formattedDate && formattedDate != 'INVALID DATE' && (
        <span className='whitespace-nowrap font-medium text-[#737373]'>
          {formattedDate}
        </span>
      )}
      {readTime && <span>-</span>}
      {readTime && (
        <span className='whitespace-nowrap font-medium text-charcoal'>
          {`${readTime} MIN READ`}
        </span>
      )}
    </div>
  );
}
