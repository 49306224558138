'use client';
import SinglePostCard from './SinglePostCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import { Article as ArticleType } from '@/types/types';
import { Grid, Pagination } from 'swiper/modules';
import { useEffect, useState } from 'react';

export default function MostRecentPosts({
  posts,
  headingClass,
  imageClass,
  id,
  breakpoints,
  className,
  centeredSlides = false,
}: {
  posts?: ArticleType[];
  headingClass?: string;
  imageClass?: string;
  id?: string;
  breakpoints?: any;
  className?: string;
  centeredSlides?: boolean;
}) {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={`mb-7 mt-7 flex flex-col pb-10 sm:gap-12 ${className}`}>
      <Swiper
        className='w-full !py-4 md:!px-4'
        modules={[Pagination, Grid]}
        pagination={{
          clickable: true,
          el: `.recent-swiper-pagination-${id}`,
        }}
        centeredSlides={centeredSlides}
        breakpoints={
          breakpoints || {
            0: {
              slidesPerView: 1,
              spaceBetween: 32,
              grid: {
                rows: 4,
                fill: 'row',
              },
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 32,
              grid: {
                rows: 4,
                fill: 'row',
              },
            },
            765: {
              slidesPerView: 2,
              spaceBetween: 32,
              grid: {
                rows: 3,
                fill: 'row',
              },
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 34,
              grid: {
                rows: 3,
                fill: 'row',
              },
            },
          }
        }
        noSwiping={isDesktop}
        noSwipingClass='swiper-no-swiping'
      >
        {posts?.map(({ excerpt, ...post }) => (
          <SwiperSlide
            key={post.slug}
            className={isDesktop ? 'swiper-no-swiping' : ''}
          >
            <SinglePostCard
              post={post as any}
              headingClass={
                headingClass ||
                'text-[18px] text-[#282825] font-sans font-semibold !line-clamp-3'
              }
              headingParentClass='!line-clamp-3'
              className='text-left'
              imageClass={
                imageClass ||
                '[&_img]:!h-[114px] [&_img]:!w-[114px] [&_img]:rounded-lg [&_img]:object-cover'
              }
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        className={`most-recent-swiper-pagination recent-swiper-pagination-${id} flex w-full items-center justify-center gap-3`}
      ></div>
    </div>
  );
}
